<template>
	<div class="container">
    <div class="content" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
      <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
        <swiper-slide class="swiper-item" v-for="item in swiperData" :key="item.id">
          <router-link target="_blank" :to="item.linkUrl">
            <img :src="item.imgUrl" alt="">
            <div class="title-box">
              <div class="title">
                {{item.title}}
              </div>
            </div>
          </router-link>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>


export default {
  name: "HomeSwiper",
  data () {
    return {
      swiperOptions: {
        pagination: {//分页器
          el: ".swiper-pagination",
        },
        // navigation: {// 前进后退按钮 ,不同版本配置方式不同
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // },
        loop: true, //开启循环模式
        autoplay: {//自动播放,不同版本配置方式不同 
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        disableOnInteraction: false,
      },
      swiperList: []
    };
  },
  computed: {
    mySwiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  props: {
    swiperData: {
      type: Array,
      default: () => []
    }
  },
  components: {
    
  },
  mounted() {
    
  },
  // watch: {
  //   swiperData(newVal, oldVal) {
  //     if(newVal&&newVal.length>0) {
  //       this.swiperList = newVal[0]
  //       console.log(this.swiperList)
  //     }
  //   }
  // },
  methods: {
    /** 鼠标移入 */
    onMouseEnter() {  
      this.mySwiper.autoplay.stop()
    },
    /** 鼠标移出 */
    onMouseLeave() {
      this.mySwiper.autoplay.start()
    }
  }
}
</script>

<style>
.swiper .swiper-pagination {
  position: absolute;
  bottom: 36px;
  right: 0;
  left: auto;
  z-index: 10;
  height: 36px;
  line-height: 36px;
}
.swiper-pagination-bullet {
  background-color: #fff;
}
.swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--theme-color) !important;
}
</style>
<style lang='less' scoped>
.container {
  width: 100%;
  height: 397px;
  .content{
    width: 100%;
    height: 397px;
    .swiper{
      position: relative;
      .swiper-item {
        height: 397px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .title-box {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 36px;
          line-height: 36px;
          background: rgba(0, 0, 0, 0.8);
          color: #fff;
          padding: 0 10px;
          box-sizing: border-box;
          .title {
            width: 100%;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
