<template>
  <div class="container">
    <div class="title">相关链接</div>
    <div class="link-box">
      <div class="link-item" v-for="item in allLinkListByType" :key="item.typeInfo.id">
        <el-select v-model="item.typeInfo.typeName" placeholder="请选择" @change="linkChange">
          <el-option
            v-for="option in item.linkList"
            :key="option.id"
            :label="option.linkName"
            :value="option.linkUrl">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FriendshipLink",
  data() {
    return {
      
    }
  },
  props: {
    allLinkListByType: {
      type: Array,
      default: () => []
    }
  },
  components: {

  },
  methods: {
    linkChange(val) {
      window.open(val);
    }
  }
}
</script>

<style lang='less' scoped>
.container {
  width: 1200px;
  margin: 40px auto 0;
  height: 122px;
  border-top: 4px solid var(--theme-color);
  .title {
    font-size: 18px;
    color: #313131;
    padding: 10px 0 10px 10px;
  }
  .link-box {
    padding: 0 5px;
    display: flex;
    flex-wrap: wrap;
    .link-item {
      width: 190px;
      margin-right: 10px;
      margin-bottom: 10px;
      &:nth-child(6n+6) {
        margin-right: 0;
      }
    }
  }
}
</style>
