import {request} from './request'
import baseUrlObj from "./baseURL"
const systemNumber = baseUrlObj.systemNumber

// 获取全部友情链接-分组 /ewi/guest/Link/GetAllLinkListByType
export function getAllLinkListByType(){
  return request({
    url: '/ewi/guest/Link/GetAllLinkListByType',
    method: 'get',
    params: {
      systemNumber: systemNumber
    }
  })
}
