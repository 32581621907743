<template>
	<div class="container">
    <div class="top">
      <div class="title-box">
        <div :class="['title-item',curTitleIndex===index?'selected':'']" v-for="(item,index) in homeNewsData.menuList" :key="item.menuId" @click="clickTitle(item,index)">{{ item.menuName }}</div>
      </div>
      <div class="more">
        <router-link target="_blank" :to="curMoreLink">更多+</router-link>
      </div>
    </div>
    <div class="bottom">
      <ul :class="['news-box',curTitleIndex===index?'selected':'']" v-for="(item,index) in homeNewsData.menuList" :key="item.menuId">
        <!-- <li class="news-item" v-for="news in item.newsList" :key="news.newsNumber">{{ news.title }}</li> -->
        <li class="news-item" v-for="news in item.newsList" :key="news.newsNumber">
          <router-link target="_blank" :to="'/newsdetails?newsNumber='+news.newsNumber">{{ news.title }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>


export default {
  name: "NewsBox",
  data () {
    return {
      curTitleIndex: 0,
      curMoreLink: ''
    };
  },
  props: {
    homeNewsData: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    
  },
  mounted() {
    this.curMoreLink = '/newslist?menuId='+this.homeNewsData.menuList[0].menuId+'&menuName='+this.homeNewsData.menuList[0].menuName
    console.log(this.curMoreLink)
  },
  methods: {
    clickTitle(item,index) {
      this.curTitleIndex = index;
      // this.curMoreLink = item.linkUrl
      this.curMoreLink = '/newslist?menuId='+item.menuId+'&menuName='+item.menuName
    }
  }
}
</script>

<style lang='less' scoped>
.container {
  width: 100%;
  height: 100%;
  .top {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--theme-color);
    .title-box {
      height: 48px;
      display: flex;
      .title-item {
        // width: 102px;
        padding: 0 15px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        border-top: 2px solid var(--theme-color);
        border-left: 2px solid var(--theme-color);
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        // border-right: 2px solid #50b57d;
        &.selected {
          background-color: #fff;
          color: var(--theme-text-color);
          // border-right: 2px solid var(--theme-color);
        }
      }
    }
    .more {
      font-size: 14px;
      color: #fefefe;
      padding: 0 15px;
      cursor: pointer;
      a {
        font-size: 14px;
        color: #fefefe;
      }
    }
  }
  .bottom {
    height: 349px;
    overflow: hidden;
    background-color: #eee;
    .news-box {
      display: none;
      padding: 0 20px;
      &.selected {
        display: block;
      }
      .news-item {
        height: 43px;
        line-height: 43px;
        border-bottom: 1px dashed #d2d2d2;
        list-style-type: none;
        padding-left: 20px;
        color: #1b1b1b;
        font-size: 16px;
        cursor: pointer;
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          width: 5px;
          height: 5px;
          background-color: #1b1b1b;
          border-radius: 50%;
          margin-right: 10px;
          position: absolute;
          left: 5px;
          top: 50%;
          transform: translateY(-50%);
        }
        a {
          color: #1b1b1b;
          font-size: 16px;
          display: inline-block;
          width: 100%;
          height: 100%;
          white-space: nowrap; 
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:hover {
          a {
            color: var(--theme-color);
          }
        }
      }
    }
  }
}
</style>
