<template>
  <div class="home">
    <div class="topping" v-if="homeAllInfo&&homeAllInfo.topNewsInfo">
      <router-link target="_blank" :to="'/newsdetails?newsNumber='+homeAllInfo.topNewsInfo.newsNumber">
        <h3 class="title">{{ homeAllInfo.topNewsInfo.title }}</h3>
        <div class="sub-title">{{ homeAllInfo.topNewsInfo.summary }}</div>
      </router-link>
    </div>

    <div class="section-top">
      <div class="left" v-if="homeAllInfo&&homeAllInfo.carouselList&&homeAllInfo.carouselList.length>0">
        <home-swiper :swiperData="homeAllInfo.carouselList[0].detailList"></home-swiper>
      </div>
      <div class="right" v-if="homeAllInfo&&homeAllInfo.menuGroupList&&homeAllInfo.menuGroupList.length>0">
        <home-news-box :homeNewsData="homeAllInfo.menuGroupList?homeAllInfo.menuGroupList[0]:{}"></home-news-box>
      </div>
    </div>

    <div class="section" v-if="topMenuGroupList&&topMenuGroupList.length>0">
      <div class="section-item" v-for="item in topMenuGroupList" :key="item.groupNumber">
        <news-box :newsData="item.menuList"></news-box>
      </div>
    </div>

    <div class="display" v-if="homeAllInfo&&homeAllInfo.productList&&homeAllInfo.productList.length>0">
      <display :displayData="homeAllInfo.productList"></display>
    </div>

    <div class="section" v-if="otherMenuGroupList&&otherMenuGroupList.length>0">
      <div class="section-item" v-for="item in otherMenuGroupList" :key="item.groupNumber">
        <news-box :newsData="item.menuList"></news-box>
      </div>
    </div>

    <div class="expert" v-if="homeAllInfo&&homeAllInfo.expertList&&homeAllInfo.expertList.length>0">
      <expert :expertData="homeAllInfo.expertList"></expert>
    </div>

    <div class="journal" v-if="homeAllInfo&&homeAllInfo.journalList&&homeAllInfo.journalList.length>0">
      <journal :journalData="homeAllInfo.journalList"></journal>
    </div>
    
    <div v-if="allLinkListByType&&allLinkListByType.length>0">
      <friendship-link :allLinkListByType="allLinkListByType"></friendship-link>
    </div>
    
  </div>
</template>

<script>
import NewsBox from '@/components/home/NewsBox'
import Display from '@/components/home/Display'
import Expert from '@/components/home/Expert'
import Journal from '@/components/home/Journal'
import FriendshipLink from '@/components/home/FriendshipLink';
import HomeSwiper from '@/components/home/HomeSwiper.vue';
import HomeNewsBox from '@/components/home/HomeNewsBox.vue';

import { getHomeAllInfo,getCacheSetWeb } from '../../network/home'
import { getAllLinkListByType } from '../../network/link'

export default {
  name: "Home",
  data () {
    return {
      homeAllInfo: {},
      topMenuGroupList: [],
      otherMenuGroupList: [],
      allLinkListByType: [],
      webSet: {}
    };
  },
  props: {
    
  },
  components: {
    NewsBox,
    Display,
    FriendshipLink,
    HomeSwiper,
    HomeNewsBox,
    Expert,
    Journal
  },
  mounted() {
    this.getHomeAllInfo()
    this.getAllLinkListByType()
    this.getCacheSetWeb()
  },
  watch: {
    
  },
  methods: {
    
    // 网络请求
    getHomeAllInfo() {
      getHomeAllInfo().then(res=>{
        if(res.status===200&&res.data) {
          this.homeAllInfo = res.data
          if(this.homeAllInfo&&this.homeAllInfo.menuGroupList&&this.homeAllInfo.menuGroupList.length>0) {
            if(this.homeAllInfo.menuGroupList.length>3) {
              this.topMenuGroupList = this.homeAllInfo.menuGroupList.slice(1,3)
              this.otherMenuGroupList = this.homeAllInfo.menuGroupList.slice(3)
            }else{
              this.otherMenuGroupList = this.homeAllInfo.menuGroupList.slice(1)
            }
          }
        }
      })
    },
    getAllLinkListByType() {
      getAllLinkListByType().then(res=>{
        if(res.status===200&&res.data) {
          this.allLinkListByType = res.data
        }
      })
    },
    getCacheSetWeb() {
      getCacheSetWeb().then(res=>{
        if(res.status===200&&res.data) {
          this.webSet = res.data

          document.title = this.webSet.siteName
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
.home {
  // width: 1200px;
  width: 100%;
  // margin: 0 auto;
  .topping {
    width: 100%;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      font-size: 46px;
      font-weight: bold;
      color: var(--theme-text-color);
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
    }
    .sub-title {
      width: 1000px;
      margin: 0 auto;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px;
      color: #535353;
    }
  }
  .section-top {
    width: 1200px;
    margin: 35px auto 0;
    height: 397px;
    display: flex;
    .left {
      width: 615px;
      height: 100%;
    }
    .right {
      width: 585px;
      height: 100%;
    }
  }
  .section {
    width: 1200px;
    margin: 40px auto 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .section-item {
      width: 586px;
      height: 396px;
      overflow: hidden;
    }
  }
  .display {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .expert {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .journal {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
</style>
